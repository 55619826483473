import { NetStandardService } from './../../services/net-standard-service';
import { CustomerNetTypeService } from 'services/customer-net-type-service';
import { NettingService } from 'services/netting-service';
import { DialogCloseResult, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { DesignTypeService } from 'services/design-type-service';
import { DimensionClassService } from 'services/dimension-class-service';
import { ErrorService } from 'services/error-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetIntentService } from 'services/net-intent-service';
import { NetService } from 'services/net-service';
import { NetShapeService } from 'services/net-shape-service';
import { NetTypeService } from 'services/net-type-service';
import { NettingTypeService } from 'services/netting-type-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { Logger } from 'aurelia-logging';
import { dimensionClass } from 'models/NetDimension';
import { AnalysisLookupDialog } from 'components/dialogs/analysis-lookup/analysis-lookup-dialog';
import { ValidationController, Validator } from 'aurelia-validation';
import { StandardValidationBuilder } from 'lib/validation';
import { I18N } from 'aurelia-i18n';
import { AsyncErrorHandler } from 'lib/ui';
import { FilterDeletedAndInactive } from 'lib/helpers/filters';

const logger: Logger = LogManager.getLogger('form-dimension');

@autoinject
export class FormDimension {
  @bindable
  private netId: number;

  @bindable
  private net: Models.Net;

  @bindable
  private netdimensionCalculated: Models.NetDimension;

  @bindable
  protected updateFunction: () => void;

  @bindable
  protected cancelFunction: () => void;

  @bindable
  protected calcFunction: () => void;

  @bindable
  protected dimensionclassType: 0 | 1 | 2;

  @bindable
  private locked: boolean = false;

  private originalObject: Models.Net;
  private nextTabIndex: number = null;
  private currentNetShape: Models.NetShape;

  protected offerDrawingsVisible: boolean = false;
  protected otherDrawingsVisible: boolean = false;
  protected fileUploaded: boolean = false;

  private refreshDimensionsSubscription: Subscription;

  protected is2021Standard = false;
  protected classType = '';

  protected nettings: Models.Netting[];
  protected nettingtypes: Models.NettingType[];
  protected dimensionclasses: Models.DimensionClass[];

  protected netTypes: Models.NetType[] = [];
  protected netTypesFiltered: Models.NetType[] = [];

  protected designTypes: Models.DesignType[] = [];

  protected netShapes: Models.NetShape[] = [];
  protected warnings = {
    weight: {
      show: false,
      message: '',
    },
  };

  constructor(
    private designTypeService: DesignTypeService,
    private dialogService: DialogService,
    private dimensionClassService: DimensionClassService,
    private customerNetTypeService: CustomerNetTypeService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netDimensionService: NetDimensionService,
    private netIntentService: NetIntentService,
    private netStandardService: NetStandardService,
    private netService: NetService,
    private netShapeService: NetShapeService,
    private nettingTypeService: NettingTypeService,
    private netTypeService: NetTypeService,
    private router: Router,
    private toastService: ToastService,
    private nettingService: NettingService,
    private utility: Utility,
    private validationController: ValidationController,
    private validator: Validator,
    private validationBuilder: StandardValidationBuilder,
    private t: I18N
  ) {
    this.warnings.weight.message = this.t.tr('validation.valueShouldNormalyBeInRangeOfXandYUnit', {
      x: '100',
      y: '20.000',
      unit: 'kg',
    });

    this.validationController = validationController;

    this.validationController.subscribe((event) => {
      if (event.type === 'reset') {
        this.warnings.weight.show = false;
      }

      if (event.type === 'validate') {
        if (!this.net.IsMorenotNet) {
          this.warnings.weight.show =
            this.net.NetDimension.Weight && (this.net.NetDimension.Weight < 100 || this.net.NetDimension.Weight > 20000);
        }
      }
    });
  }

  protected applyValidationRules() {
    // Only apply validation rules if the net is not a mørenot net
    if (this.net.IsMorenotNet) return;

    this.validationBuilder
      .with(this.net.NetDimension)
      .required('SideCount', () => {
        return this.net.NetDimension.NetShapeId != 9;
      })
      .min('SideCount', 4)
      .max('SideCount', 100)
      .required('DepthBottom')
      .min('DepthBottom', 0)
      .max('DepthBottom', 100)
      .required('DepthBottomCenter')
      .min('DepthBottomCenter', 0)
      .max('DepthBottomCenter', 100)
      .required('DepthBottomCenterExtraBottom', () => {
        return this.net.NetDimension.NetShapeId === 3 || this.net.NetDimension.NetShapeId === 7;
      })
      .min('DepthBottomCenterExtraBottom', 0)
      .max('DepthBottomCenterExtraBottom', 100)
      .required('Weight', () => {
        return this.is2021Standard;
      })
      .min('Weight', 0)
      .required('SideRopes')
      .min('SideRopes', 1)
      .required('CircumferenceProduction')
      .custom('CircumferenceProduction', 'validation.circumferenceOutOfRange', (value) => {
        const circumference = +value;

        if (!circumference) return true;
        if (!this.net.NetDimension?.Circumference) return true;

        const maxCircumference = this.net.NetDimension.Circumference * 1.1;

        if (circumference < this.net.NetDimension.Circumference || circumference > maxCircumference) {
          return false;
        }
        return true;
      })
      .required('BottomRopeCircumference', () => {
        return this.net.NetDimension.NetShapeId != 3 && this.net.NetDimension.NetShapeId != 7;
      })
      .on(this.net.NetDimension);
  }

  async activate(params: { NetId?: number; Id?: number }) {
    this.netId = params.NetId || params.Id;

    await this.getNet(this.netId);

    await Promise.all([this.getNetTypes(), this.getNetShapes(), this.getDesignTypes(), this.getDimensionClasses(), this.getNettingTypes()]);

    this.filterNetTypesBasedOnNetShape();

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });

    this.refreshDimensionsSubscription = this.eventAggregator.subscribe('refresh-calculated-dimensions', () => {
      this.getNetDimensionCalculated();
    });
  }

  created() {
    if (this.router.currentInstruction.config.name === 'offer-detail-dimension') {
      this.offerDrawingsVisible = true;
      this.otherDrawingsVisible = false;
    } else {
      this.offerDrawingsVisible = false;
      this.otherDrawingsVisible = true;
    }
  }

  protected getNettingTypeName(netting: Models.Netting) {
    if (netting.NettingTypeId) {
      return this.nettingtypes.filter((nettingType) => nettingType.Id === netting.NettingTypeId).map((nettingType) => nettingType.Name);
    } else {
      let nettingTypeName = '';
      if (netting.Material) {
        nettingTypeName += netting.Material.Name + ' ';
      }
      if (netting.ThreadType) {
        nettingTypeName += netting.ThreadType.Name + ' ';
      }
      if (netting.NylonType) {
        nettingTypeName += netting.NylonType.Name + ' ';
      }
      if (netting.MeshSize) {
        nettingTypeName += netting.MeshSize.Name;
      }
      return nettingTypeName;
    }
  }

  setNetStandard(standardId: number) {
    if (!this.net || this.net.NetDimension?.NetStandardId == standardId) {
      return;
    }

    this.net.NetDimension.NetStandardId = standardId ? +standardId : null;

    void this.setNetStandardFlag();
    this.getNetDimensionCalculated();
  }

  // Get net and netdimension
  private async getNet(id: number) {
    const res = await this.netService.get(id + '?$expand=NetDimension');
    this.net = res;
    this.locked = res.Locked || this.netIsLinkedToAnalysis(res.NetDimension) ? true : false;

    if (this.net.NetDimension.DimensionClassId) {
      if (this.net.NetDimension.DimensionClassId === 1) {
        this.dimensionclassType = 0;
      } else if (this.net.NetDimension.DimensionClassId === 10) {
        this.dimensionclassType = 1;
      } else if (this.net.NetDimension.DimensionClassId > 1 && this.net.NetDimension.DimensionClassId < 9) {
        this.dimensionclassType = 2;
      }
    }

    if (this.net.NetDimension.NetShapeId) {
      this.currentNetShape = (await this.netShapeService.getAllCached()).find((x) => x.Id == this.net.NetDimension.NetShapeId);
    }

    this.getNetDimensionCalculated();
    this.updateOriginalObject();
    this.applyValidationRules();
    void this.setNetStandardFlag();

    this.nettings = await this.nettingService.getAll('?$filter=NetId eq ' + id + '&$expand=MeshSize,NylonType,ThreadType,Material');
  }

  protected netIsLinkedToAnalysis(netDimension: Models.NetDimension) {
    if (!netDimension) return false;
    return (
      !!netDimension.AnalysisVariantId ||
      !!netDimension.AnalysisVariant2Id ||
      !!netDimension.AnalysisVariant3Id ||
      !!netDimension.AnalysisVariant4Id ||
      !!netDimension.AnalysisVariant_2Id ||
      !!netDimension.AnalysisVariant2_2Id ||
      !!netDimension.AnalysisVariant3_2Id ||
      !!netDimension.AnalysisVariant4_2Id
    );
  }

  private getNetDimensionCalculated() {
    this.netDimensionService
      .calculateValues(this.net.Id, this.net.NetDimension)
      .then((res) => {
        this.netdimensionCalculated = res;
        if (!this.locked && this.net.IsMorenotNet) {
          if (this.netdimensionCalculated?.NetStandard?.Version === Models.NetStandardVersion.NS9415_2021) {
            this.net.NetDimension.DimensionClassId = this.netdimensionCalculated.CalculatedDimensionClassId;
          } else {
            if (this.net.NetDimension.DimensionClassId > 11) {
              this.setDimensionClassId(this.netdimensionCalculated.CalculatedDimensionClassId);
            }

            if (
              !this.net.NetDimension.OverrideDimensionClass &&
              this.net.NetDimension.DimensionClassId !== this.netdimensionCalculated.CalculatedDimensionClassId
            ) {
              this.net.NetDimension.DimensionClassId = this.netdimensionCalculated.CalculatedDimensionClassId;

              if (this.netdimensionCalculated.CalculatedDimensionClassId === 1) {
                this.dimensionclassType = 0;
              }

              if (this.netdimensionCalculated.CalculatedDimensionClassId > 1 && this.netdimensionCalculated.CalculatedDimensionClassId < 9) {
                this.dimensionclassType = 2;
              }
            }
          }

          // for coned/spagetti nets, calculate the bottom rope circumference
          if (
            this.net.NetDimension.NetShapeId === 3 ||
            this.net.NetDimension.NetShapeId === 7 ||
            (this.net.NetDimension.NetShapeId == 9 && this.net.IsMorenotNet)
          ) {
            this.net.NetDimension.BottomRopeCircumference = this.netdimensionCalculated.BottomRopeCircumference;
          }
          this.classType = dimensionClass[this.netdimensionCalculated.CalculatedDimensionClassId];
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateNet() {
    const netModification = new Models.Net();

    let changed = false;

    if (this.originalObject.CustomerNetTypeId !== this.net.CustomerNetTypeId) {
      netModification.CustomerNetTypeId = this.net.CustomerNetTypeId;
      changed = true;
    }

    if (this.originalObject.DrawingFileId !== this.net.DrawingFileId) {
      netModification.DrawingFileId = this.net.DrawingFileId;
      changed = true;
    }

    if (this.originalObject.ProductionDrawingFileId !== this.net.ProductionDrawingFileId) {
      netModification.ProductionDrawingFileId = this.net.ProductionDrawingFileId;
      changed = true;
    }

    if (this.originalObject.CustomerDrawingFileId !== this.net.CustomerDrawingFileId) {
      netModification.CustomerDrawingFileId = this.net.CustomerDrawingFileId;
      changed = true;
    }

    if (changed) {
      netModification.Id = this.net.Id;

      this.netService
        .put(netModification, netModification.Id)
        .then((result) => {
          this.eventAggregator.publish('offer-refresh', 'offer');

          this.toastService.showSuccess('net.updated');
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  private async updateNetDimension() {
    const { valid } = await this.validationController.validate();
    if (!valid) {
      this.toastService.showWarning('general.checkValidationErrors');
      return;
    }

    // weight is always calculated server side for none mørenot nets, so dont save this information
    if (this.net.IsMorenotNet) {
      delete this.net.NetDimension.Weight;
    }

    this.netDimensionService
      .put(this.net.NetDimension, this.net.NetDimension.Id)
      .then((res) => {
        // Also update net if DesignType has changed
        if (
          this.originalObject.CustomerNetTypeId !== this.net.CustomerNetTypeId ||
          this.originalObject.DrawingFileId !== this.net.DrawingFileId ||
          this.originalObject.ProductionDrawingFileId !== this.net.ProductionDrawingFileId ||
          this.originalObject.CustomerDrawingFileId !== this.net.CustomerDrawingFileId
        ) {
          const netCopy = JSON.parse(JSON.stringify(this.net));
          netCopy.NetDimension = null;
          netCopy.Nettings = null;
          this.netService
            .put(netCopy, netCopy.Id)
            .then((result) => {
              this.toastService.showSuccess('net.updated');
            })
            .catch((err) => this.errorService.handleError(err));
        }

        this.updateOriginalObject();
        this.toastService.showSuccess('netdimension.updated');

        this.eventAggregator.publish('offer-refresh', 'price');
        this.eventAggregator.publish('offer-refresh', 'offer');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateOriginalObject() {
    if (!this.net) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.net));
  }

  async attached() {}

  private detached() {
    if (this.refreshDimensionsSubscription) {
      this.refreshDimensionsSubscription.dispose();
    }
  }

  private deactivate() {
    if (this.refreshDimensionsSubscription) {
      this.refreshDimensionsSubscription.dispose();
    }
  }

  protected async setNetShape(netShapeId: number) {
    this.net.NetDimension.NetShapeId = netShapeId;

    if (netShapeId) {
      this.currentNetShape = (await this.netShapeService.getAllCached()).find((x) => x.Id == this.net.NetDimension.NetShapeId);

      if (!this.currentNetShape?.CanUseCombiNet) {
        this.net.NetDimension.IsCombiNet = false;
      }
    }

    this.filterNetTypesBasedOnNetShape();
    this.getNetDimensionCalculated();
  }

  private setTableNet() {
    this.dimensionclassTypeChanged(2, this.dimensionclassType);
    return true;
  }

  private dimensionclassTypeChanged(newValue, oldValue) {
    if (newValue.toString() === '2') {
      // tabellnot - kun lov hvis kalkulert DimensionClassId er 2-8 (klasse 1-7)
      if (this.netdimensionCalculated) {
        if (this.net && this.net.NetDimension) {
          this.dimensionclassType = 2;
          this.net.NetDimension.Hs = +'2.5';
          this.net.NetDimension.Vs = +'0.75';
          this.net.NetDimension.DimensionClassId = this.netdimensionCalculated.CalculatedDimensionClassId;
          this.net.NetDimension.OverrideDimensionClass = false;
        }
      }
    }
  }

  async setNetStandardFlag() {
    const standards = await this.netStandardService.getAllCached();
    const standard = standards.find((s) => +s.Id === +this.net.NetDimension.NetStandardId);
    this.is2021Standard = standard?.Version === Models.NetStandardVersion.NS9415_2021;
    if (this.is2021Standard) this.dimensionclassType = 0;
    this.eventAggregator.publish('net-standard-changed', standard);
  }

  async getNetStandards() {
    if (!this.net.IsMorenotNet) {
      return await this.netStandardService.getAll();
    } else {
      //NetStandard RES 1821 (Chile) is temporary only visible and used in used net so hide here
      return await this.netStandardService.getAll('?$filter=Id ne ' + Models.NetStandardId.RES_1821);
    }
  }

  private setDimensionClassId(dimensionClassId: number) {
    this.net.NetDimension.DimensionClassId = dimensionClassId;
    this.net.NetDimension.OverrideDimensionClass = true;

    if (this.net.NetDimension.DimensionClassId === 1) {
      this.dimensionclassType = 0;
    } else if (this.net.NetDimension.DimensionClassId === 10) {
      this.dimensionclassType = 1;
    } else if (this.net.NetDimension.DimensionClassId > 1 && this.net.NetDimension.DimensionClassId < 9) {
      this.dimensionclassType = 2;
    }

    return true;
  }

  private setDrawingFileId(event: any) {
    this.net.DrawingFileId = event.detail.id;
    this.fileUploaded = true;
    this.updateNet();
    logger.debug('fileUploaded.');
  }

  private setCustomerDrawingFileId(event: any) {
    this.net.CustomerDrawingFileId = event.detail.id;
    this.fileUploaded = true;
    this.updateNet();
    logger.debug('fileUploaded..');
  }

  private setProductionDrawingFileId(event: any) {
    this.net.ProductionDrawingFileId = event.detail.id;
    this.fileUploaded = true;
    this.updateNet();
    logger.debug('fileUploaded...');
  }

  private removeDrawingFileId() {
    this.net.DrawingFileId = null;
    this.updateNet();
  }

  private removeCustomerDrawingFileId() {
    this.net.CustomerDrawingFileId = null;
    this.updateNet();
  }

  private removeProductionDrawingFileId() {
    this.net.ProductionDrawingFileId = null;
    this.updateNet();
  }

  private cancel() {
    if (this.router.baseUrl.includes('offer')) {
      this.router.navigateToRoute('offer-list');
    }
    if (this.router.baseUrl.includes('order')) {
      this.router.navigateToRoute('order-list');
    }
    if (this.router.baseUrl.includes('net-detail')) {
      this.router.navigateToRoute('net-list');
    }
  }

  private canDeactivate() {
    if (this.originalObject && !this.utility.areEqual(this.net, this.originalObject) && !this.locked) {
      // tslint:disable-next-line:no-console
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.pleaseConfirmHeader',
            message: 'dialog.unsavedChangesText',
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // save the nettingtype and let that function handle the rest of the logic
              void this.updateNetDimension();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  protected lookup(slot: number | undefined, part: number) {
    this.dialogService
      .open({
        viewModel: AnalysisLookupDialog,
        model: {
          netId: this.net?.Id,
          slot,
        },
        lock: false,
        position: () => {
          //
        },
      })
      .whenClosed(async (response: DialogCloseResult) => {
        if (response.wasCancelled) return false;
        const analysis = response.output;

        try {
          await this.netDimensionService.linkAnalysis(this.net.NetDimensionId, {
            Slot: slot,
            AnalysisId: analysis.AnalysisId,
            Part: part,
            AnalysisVariantId: analysis.AnalysisVariantId,
          });
          await this.getNet(this.net.Id);
        } catch (error) {
          this.errorService.handleError(error);
        }

        document.querySelector('html').style.overflowY = null;
      })
      .catch((err) => {
        document.querySelector('html').style.overflowY = null;
        this.errorService.handleError(err);
      });
  }

  protected async removeAnalysis(slot: number, part: number) {
    try {
      await this.netDimensionService.unLinkAnalysis(this.net.NetDimensionId, {
        Slot: slot,
        Part: part,
      });
      await this.getNet(this.net.Id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onIsCollectChanged(isCollect: boolean) {
    this.net.IsCollect = isCollect;
    try {
      await this.netService.put(
        {
          IsCollect: isCollect,
        } as Models.Net,
        this.net.Id
      );
      this.toastService.showSuccess('net.updated');
      await this.getNet(this.net.Id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected setNetType(netTypeId: number | undefined) {
    this.net.NetDimension.NetTypeId = netTypeId;
    const type = this.netTypes.find((x) => x.Id == netTypeId);

    const circumference = type?.Circumference || 0;
    this.net.NetDimension.Circumference = circumference;
    this.net.NetDimension.CircumferenceProduction = circumference;
  }

  protected setNetDesign(designTypeId: number | undefined) {
    this.net.NetDimension.DesignTypeId = designTypeId;
  }

  protected filterNetTypesBasedOnNetShape() {
    if (!this.net?.NetDimension || !this.net.NetDimension.NetShapeId) return;
    const isSquaredNet = this.net.NetDimension.NetShapeId == 9;

    this.netTypesFiltered = [];

    setTimeout(() => {
      this.netTypesFiltered = this.netTypes
        .filter((x) => {
          return isSquaredNet ? x.Name.includes('x') : !x.Name.includes('x');
        })
        .filter((x) => FilterDeletedAndInactive(x));
    }, 30);
  }

  @AsyncErrorHandler
  protected async getNetTypes() {
    this.netTypes = await this.netTypeService.getAllCached();
    this.netTypesFiltered = this.netTypes;
    return this.netTypes;
  }

  @AsyncErrorHandler
  protected async getDesignTypes() {
    const res = await this.designTypeService.getAllCached();
    this.designTypes = res.filter((v) => FilterDeletedAndInactive(v));
  }

  @AsyncErrorHandler
  protected async getNetShapes() {
    const res = await this.netShapeService.getAllCached();
    this.netShapes = res.filter((v) => FilterDeletedAndInactive(v));
  }

  @AsyncErrorHandler
  protected async getNettingTypes() {
    const res = await this.nettingTypeService.getAllCached();
    this.nettingtypes = res.filter((v) => FilterDeletedAndInactive(v));
  }

  @AsyncErrorHandler
  protected async getDimensionClasses() {
    const classes = await this.dimensionClassService.getAllCached();
    this.dimensionclasses = classes.filter((obj) => obj.Id > 1 && obj.Id < 9);
  }
}
